import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`When the BBC ventured into voice, they decided to tackle the toughest demographic first: children. Lisa Vigar and Paul Jackson are two of the minds tasked with creating the BBC Kids voice skill, CBeebies. We invited them to `}<a parentName="p" {...{
        "href": "https://sb2019.dashbot.io/"
      }}>{`SuperBot 2019`}</a>{` to speak on some of the trials and tribulations they faced after launching their voice skill and how they went about resolving those issues.`}</p>
    <p>{`The BBC is taking all the principles Lisa and Paul gathered from building voice skills for kids and applying them throughout the entire organization.`}</p>
    <iframe allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen="" frameBorder="0" height="675" src="https://www.youtube.com/embed/ijeBt0dsF3s?feature=oembed" width="1200"></iframe>
    <h2>{`Subscribe to our event mailing list`}</h2>
    <p>{`Thank you to everyone who participated in SuperBot and made it a huge success. We love bringing together people to share and learn from each other, so we regularly host meetups in New York City and San Francisco.`}</p>
    <p>{`If you’d like to hear more and be notified about Dashbot events, sign up for our mailing list here.`}</p>
    <style type="text/css">
  {`
	#mc_embed_signup {
	background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif; }
		/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block. We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
    #mc_embed_signup .button {background: #ff6a51;}  
    #mc_embed_signup .button:hover {background: #30aabd;}
`}
    </style>
    <form action="https://dashbot.us13.list-manage.com/subscribe/post?u=a7c5d5ea7f4d59ddd76ed312c&id=e5438a1680" id="mc-embedded-subscribe-form" method="post" name="mc-embedded-subscribe-form" noValidate="" target="_blank">
\* indicates required  
      <label htmlFor="mce-EMAIL">Email Address \*  
      </label>  
      <input id="mce-EMAIL" name="EMAIL" type="email" value=""></input>  
      <label htmlFor="mce-FNAME">First Name \*</label>  
      <input id="mce-FNAME" name="FNAME" type="text" value=""></input>  
      <label htmlFor="mce-LNAME">Last Name \*</label>  
      <input id="mce-LNAME" name="LNAME" type="text" value=""></input>  
      <label htmlFor="mce-MMERGE3">Job Title </label>  
      <input id="mce-MMERGE3" name="MMERGE3" type="text" value=""></input>  
      <label htmlFor="mce-MMERGE4">Company </label>  
      <input id="mce-MMERGE4" name="MMERGE4" type="text" value=""></input>  
**Event Locations**
      <ul>
        <li parentName="ul">
          <input id="mce-group[12769]-12769-0" name="group[12769][1]" type="checkbox" value="1"></input>
          <label htmlFor="mce-group[12769]-12769-0">San Francisco</label>
        </li>
        <li parentName="ul">
          <input id="mce-group[12769]-12769-1" name="group[12769][2]" type="checkbox" value="2"></input>
          <label htmlFor="mce-group[12769]-12769-1">New York</label>
        </li>
        <li parentName="ul">
          <input id="mce-group[12769]-12769-2" name="group[12769][4]" type="checkbox" value="4"></input>
          <label htmlFor="mce-group[12769]-12769-2">Superbot</label>
        </li>
      </ul>
      <input name="b_a7c5d5ea7f4d59ddd76ed312c_e5438a1680" tabIndex="-1" type="text" value=""></input>
      <input id="mc-embedded-subscribe" name="subscribe" type="submit" value="Subscribe"></input>
    </form>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      